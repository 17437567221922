import React from "react";
import "./originStory.scss";

const OriginStory = () => {
  return (
    <div className="originStory-main-wrapper">
      <div className="originStory-inner-wrapper">
      <div className="page-heading center mb-15">
        <div className="title akira pt-0">AN ORIGIN STORY</div>
        <div className="underline"></div>
      </div>
          {/* Paraghraph1 Start */}
        <div className="origin-title">
          The Cool Cats Master Challenge - <span>#29means29</span>
        </div>
        <p>
          The NLL takes its origins back to May 25th, 2021 when the final
          challenge Moment™ of the season long Top Shot Master Challenge surfaced. Back at the start of Series 2,
          fans were tasked with collecting 29 of
          the coolest Top Shot Moments to be released in a combination of drops
          and challenges throughout the entire 2020-21 NBA season.
          <br />
          <br />
          <i>The reward:</i> and exclusive Dunk of ROTY <a href="https://nbatopshot.com/listings/p2p/ad8e85a4-2240-4604-95f6-be826966d988+424f7154-647a-4ff2-84fb-8831fbbc7484" target="_blank"><span>LaMelo Ball</span></a> - his
          only dunk minted to date (4/24/22).
          <br />
          <br />
          Shortly after the 29th Moment of the challenge dropped, an organic
          discord community began to form at
          the hands of <i>BakraParty</i>, <i>Chugsnbugs</i>, and <i>PlungeFather</i>. Their goal was
          simple: incentivize comradery around challenge finishers, inspire "creating your own utility", and
          find anyone anything who wanted to #HODLamelo togather. Entrance into the ground was even simpler:
          hold all 29 Cool Cats Moments... no exceptions!
        </p>
          {/* Paraghraph1 End */}
          {/* Paraghraph2 Start */}
        <div className="origin-title">
          The Nine Lives Lounge - <span>30/30</span>
        </div>
        <p>
          Upon successful traction of the group, Dapper Labs stepped up to
          create an official Cool Cats discord channel within the NBA Top Shot 
          server of full set holders. This was done to both support and grow the 
          Cool Cats community, as well as to develop a way to reward their most
          trusted backers. 
          <br />
          <br />
          To honor what came before, Dapper gave the OG community the privilege
          of giving the club its official
          name. And thanks to @RealRedDad, the Nine Lives Lounge was born.{" "}
          <br />
          <br />
          Now, a group of founding NLL members have come together to add new
          utility to Lounge - 
          focusing primarily on expanding the community into the metaverse and
          building a global brand. 
          <br />
          <br />
          Join us as these master challengers seek to show the world what true
          fandom is all about!
        </p>
        {/* Paraghraph2 End */}
      </div>
    </div>
  );
};

export default OriginStory;
