import React, { useState } from "react";
import "./faqs.scss";

const Faqs = () => {
  const [open, setOpen] = useState(true);
  const [faqs, setFaqs] = useState([
    {
      question: "What is the difference between the NLL and IX. Labs?",
      answer:
        "The Nine Lives Lounge (NLL) is a groundbreaking NFT club derived from the first ever NBA Top Shot Master Challenge, which required fans to collect all 30 NFTs from the Cool Cats Moment™ set. IX. Labs is a Web3 and NFT service provider, built and designed by founding members of the NLL.",
      open: false,
    },
    {
      question: "Are these projects from Dapper Labs?",
      answer: "IX. Labs is a third-party service provider and is NOT affiliated with Dapper Labs or NBA Top Shot.",
      open: false,
    },
    {
      question: "What is the metaverse?",
      answer:
        "The metaverse is a digital iteration of the Internet as a single, universal and immersive world that is experienced by the use of virtual reality (VR) and augmented reality (AR). In other terms, a metaverse is a network of 3D virtual worlds focused on social connection.",
      open: false,
    },
    {
      question: "What is the 30/30 Badge NFT?",
      answer:
        "The 30/30 Badge is an exclusive NFT for all current and future NLL members. This Badge grants the holder early access to future mints, exclusive discounts, private NFT alpha and more. Become a member of the Lounge, claim your pass, and join us on our journey into the metaverse!",
      open: false,
    },
    {
      question: "Can I get a second 30/30 Badge if I sell mine on the open market?",
      answer:
        "No this is not possible. Once you have claimed your original 30/30 Badge, you cannot claim another if you sell it on a secondary marketplace. To be specific, there is only one 30/30 Badge claimable per Top Shot account.",
      open: false,
    },
    {
      question: "If I sell my Cool Cats set, do I get to keep my 30/30 Badge NFT?",
      answer:
        "Yes, your 30/30 Badge is yours and is a memento for you to keep. We pride ourselves to help all former NLL remember the Lounge.",
      open: false,
    },
    {
      question: "What are Master Cats?",
      answer:
        "The IX. Labs genesis drop will include our very own Master Cats NFT — a gang of 9,000 cats equipped with nine lives, cool furs, and over 99+ traits. The first ever trait-based NLL Avatar, this PFP will be unlike anything the metaverse has seen.",
      open: false,
    },
    {
      question: "How do I get a Master Cat NFT?",
      answer:
        "Master Cat NFT’s can be claimed for free by all current NLL members on May 25th-27th. After which a public and presale will take place. Please visit the <a href='https://mastercatsnft.io' target='_blank'>Master Cats Website</a> for more details.",
      open: false,
    },
    {
      question: "Does having a 30/30 Badge or a Master Cat make me a member of the NLL?",
      answer:
        "<b>NO!</b> The only way into the NLL is to collect all 30 Cool Cats Moments #30means30. However, as a holder of the 30/30 Badge or a Master Cat, you will officially be a part of the IX. Labs ecosystem and brand — granting you direct exposure to The Lounge.",
      open: false,
    },
    {
      question: "When will the VR Lounge be ready, and who can access it?",
      answer:
        "The Cat Perch is still in development, and is anticipating to open its doors in Q4.",
      open: false,
    },
    {
      question: "What VR world will the Cat Perch be in?",
      answer:
        "The IX. Labs team is considering all major VR worlds in the Web3 space at this time. We will let the community know of an update when this has been decided.",
      open: false,
    },
    {
      question: "Who/where can we contact to establish a partnership/collab with IX. Labs?",
      answer:
        "Please reach out and direct all inquiries to: collab@nineliveslounge.io",
      open: false,
    },
  ]);

  const handleOpeningFunction = (index) => {
    let newArr = [];
    for (let i = 0; i < faqs.length; i++) {
      if (i === index) {
        newArr.push({ ...faqs[i], open: !faqs[i].open });
      } else {
        newArr.push({ ...faqs[i], open: false });
      }
    }
    setFaqs(newArr);
  };

  return (
    <div className="faqs-main-wrapper">
      <div className="faq-heading">
        <h1>Frequently Asked Questions</h1>
        {open ? (
          <i
            className="fal fa-angle-up faq-caret"
            onClick={() => setOpen((prev) => !prev)}
          ></i>
        ) : (
          <i
            className="fal fa-angle-down faq-caret"
            onClick={() => setOpen((prev) => !prev)}
          ></i>
        )}
      </div>
      {open ? (
        <div className="faq-list">
          {faqs.map((item, i) => (
            <div className="faq-item" key={i}>
              <div
                className="question"
                style={item.open ? { color: "rgb(228, 1, 228)" } : null}
                onClick={() => handleOpeningFunction(i)}
              >
                {item.question}
                {item.open ? (
                  <i className="fal fa-angle-up"></i>
                ) : (
                  <i className="fal fa-angle-down"></i>
                )}
              </div>
              <p style={!item.open ? { display: "none" } : null} dangerouslySetInnerHTML={{__html: item.answer}}>
              </p>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default Faqs;
