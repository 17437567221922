import { Navigation, Autoplay, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/autoplay/autoplay.scss';
import 'swiper/modules/mousewheel/mousewheel.scss';

import "./slider.scss";

import badge3030 from "../../../../../../assets/images/lounge/banners/3030_badge.jpg";
import artistSpotlight from "../../../../../../assets/images/lounge/banners/artist_spotlight.jpg";
import becomeMember from "../../../../../../assets/images/lounge/banners/become_member.jpg";
import ccSet from "../../../../../../assets/images/lounge/banners/cc_set.jpg";
import poker from "../../../../../../assets/images/lounge/banners/poker.jpg";

const Slider = () => {
  return (
    <div className="lounge-swiper">
      {/* Desktop view */}
      <div className="Desktop-view">
        <Swiper
          modules={[Autoplay, Mousewheel]}
          className="mySwiper"
          autoplay={{delay: 4000, disableOnInteraction: true}}
          mousewheel={{releaseOnEdges: true}}
          threshold={5}
          simulateTouch={true}
          centeredSlides={true}
          speed={600}
          loop={true}
          slidesPerView={1}
          initialSlide={1}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 2,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 2,
            },
            1000: {
              slidesPerView: 2,
            },
            1400: {
              slidesPerView: 3,
            },
          }}
          spaceBetween={20}
          onSwiper={() => {
            console.log("onSwiper")
          }}
          onSlideChange={(swiper) => {
            console.log("swiper.realIndex", swiper.realIndex)
          }}
        >
          <SwiperSlide>
            <a href="/claim">
              <div className="banner-container">
                <img src={badge3030} alt="" />
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a target="_blank" href="https://nbatopshot.com/sets/ad8e85a4-2240-4604-95f6-be826966d988">
              <div className="banner-container">
                <img src={becomeMember} alt="" />
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a target="_blank" href="https://www.yuzhangart.com/">
              <div className="banner-container">
                <img src={artistSpotlight} alt="" />
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
           <a target="_blank" href="https://nineliveslounge.medium.com/want-a-ticket-to-the-nine-lives-lounge-heres-your-chance-to-score-a-complete-cool-cats-set-22f5bc7e56ac">
              <div className="banner-container">
                <img src={ccSet} alt="" />
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a target="_blank" href="https://discord.gg/nbatopshot">
              <div className="banner-container">
                <img src={poker} alt="" />
              </div>
            </a>
          </SwiperSlide>
        </Swiper>
      </div>

      {/* mobile view*/}
      <div className="mobile-view">
        <Swiper
          spaceBetween={10}
          slidesPerView={2}
          grabCursor={true}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src={badge3030} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={becomeMember} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={artistSpotlight} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={ccSet} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={poker} alt="" />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Slider;
