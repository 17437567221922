
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import nllLandingImg from "../../assets/images/landing/nll-landing-1.png";
import nllCatImg from "../../assets/images/landing/nll-cat.png";
import nllPinkTitleImg from "../../assets/images/landing/nll-pink-title.png";
import shapeImg from "../../assets/images/landing/shape.png";

import './Landing.scss';

export default function Landing() {
  const [portalIndex, setPortalIndex] = useState(0);

  let navigate = useNavigate(); 
  const routeChange = () =>{
    const path = `/portal`; 
    navigate(path);
  }

  useEffect(() => {
    document.body.classList.add('bg-multi-cat');

    return () => {
        document.body.classList.remove('bg-multi-cat');
    };
  });

  return (
    <main className="upper-main landing">
    <section className="main-wrapper">
      <div className="container">
        <div className="main-bg">
          <img src={nllLandingImg} className="bg-full" alt="hero-img"/>
          <img src={nllCatImg} className="logo" alt=""/>
          <div className="social-list">
            <ul>
              <li>
                <a href="http://hoo.ps/cool-cats-moments-kd" target="_blank">
                  <div className="soc-btn topshot"></div>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/NineLivesLounge" target="_blank">
                  <div className="soc-btn twitter"></div>
                </a>
              </li>
              <li>
                <a href="https://discord.gg/nbatopshot" target="_blank">
                  <div className="soc-btn discord"></div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section className="about-section">
      <div className="container">
        <div className="row gy-lg-0 gy-4 justify-content-center align-items-end">
          <div className="col-lg-5">
            <div className="about-text">
              <h1 className="wow fadeInUp" data-wow-delay=".1s">
                <img src={nllPinkTitleImg} alt=""/>
              </h1>
              <div className="texture-btn wow fadeInLeft" data-wow-delay=".7s" onClick={routeChange}> ENTER </div>
            </div>
          </div>
          <div className="col-lg-6 wow fadeInLeft" data-wow-delay=".7s">
            <div className="inner-text">
              <p> An exclusive NFT club of the coolest cats in all of crypto.<br />
                Collect all 30 Moment<sup>TM</sup> NFTs and join us! </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* <img src={shapeImg} className="shape-landing" alt="shape"/> */}
  </main>
  );
}
