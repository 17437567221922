import React from "react";
import Slider from "./components/Slider";

import "./topSlider.scss";

const TopSlider = () => {
  return (
    <div className="topSlider-main-wrapper">
        <Slider />
    </div>
  );
};

export default TopSlider;
