import React from "react";
import "./councilAtLarge.scss";

const CouncilAtLarge = () => {
  return (
    <div className="CouncilAtLarge-main-wrapper">
      <div className="CouncilAtLarge-inner-wrapper">
        <div className="page-heading left">
          <div className="title">COUNCIL AT-LARGE</div>
        </div>
        <p className="CouncilAtLarge-paragraph">
          The NLL Council is a independent entity established to serve as a
          liason between Dapper Labs and the NLL community. It is comprised
          solely of Cool Cats holders Who volunteer, serving in the capacity as
          chief stewards of NLL. Their duties may includes carrying out the
          resolutions, policies, and activities of the council, as duly elected
          representatives of the NLL community. The council positions, seasons,
          members, and by-laws are decided exclusively by community vote.
        </p>
      </div>
    </div>
  );
};

export default CouncilAtLarge;
