import React from "react";
import metaversehq from "../../../../assets/images/metaverse/metaversehq.jpg";

const MetaverseHQ = () => {
  return (
    <div
      className="metaverseHQ-main-wrapper"
      style={{
        backgroundImage: `url(${metaversehq})`,
        height: "calc(100vh)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="page-hero-heading">
        <div className="title">
          METAVERSE
        </div>
        <div className="underline"></div>
      </div>
    </div>
  );
};

export default MetaverseHQ;
