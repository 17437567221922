import './Badge.scss';

const Badge = ({ title, className = '' }) => {
    return (
        <div className={`badge-base ${className} p-relative akira d-inline-block`}>
            {title}
            <span />
        </div>
    )
}

export default Badge;