import React from "react";
import { Tweet } from "react-twitter-widgets";
import "./twitterFeed.scss";
import twitter1 from "../../../../../../assets/images/lounge/twitter1.png";
import twitter2 from "../../../../../../assets/images/lounge/twitter2.png";
const TwitterFeed = () => {
  return (
    <div className="twitterfeed-wrapper">
      {/* <div className="lounge-subtitle akira">
        TWITTER FEED
      </div> */}
      <div className='twitter-img'>
        <div className="tweet-container">
          <Tweet tweetId="1504143317260374019" options={{ width:300 }} />
        </div>
        {/* <div className="tweet-container">
          <Tweet tweetId="1519861407810043904" options={{  width:300 }} />
        </div> */}
      </div>
    </div>
  );
};

export default TwitterFeed;