import React, { useRef, useState } from "react";
import { Navigation, Autoplay, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/autoplay/autoplay.scss';
import 'swiper/modules/mousewheel/mousewheel.scss';

import "./styles.scss";

import profile1 from "../../../../../../assets/images/lounge/profile1.jpg";
import profile2 from "../../../../../../assets/images/lounge/profile2.png";
import profile3 from "../../../../../../assets/images/lounge/profile3.png";
import profile4 from "../../../../../../assets/images/lounge/profile4.jpg";
import profile5 from "../../../../../../assets/images/lounge/profile5.png";

export default function ProfileSlider() {
  return (
    <>
    <div className="lounge-subtitle akira">
      CERTIFIED BALLERS
    </div>
      {/* Desktop veiw */}
      <div className="desktop-viewSlider">

      <Swiper
        spaceBetween={20}
        slidesPerView={5}
        grabCursor={true}
        navigation={false}
        modules={[Navigation]}
        className="belowSwiper"
        >
        <SwiperSlide className="below-swiper-slide">
          <div className="baller-wrapper">
            <a target="_blank" href="https://nbatopshot.com/user/@Hbarnes">
              <img src={profile1} alt="" />
              <h5>Harrison Bar...</h5>
              <div className="tickWrapper">
              <div className="topshot-name">Hbarnes</div>
              <span>
                <i className="tickIcon fas fa-check"></i>
              </span>
              </div>
              <p>
                Kings <span>|</span> 40
              </p>
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide className="below-swiper-slide">
        <div className="baller-wrapper">
        <a target="_blank" href="https://nbatopshot.com/user/@TH0">
          <img src={profile2} alt="" />
          <h5>Tyrese Halib...</h5>
          <div className="tickWrapper">
          <div className="topshot-name">THO</div>
          <span>
            <i className="tickIcon fas fa-check"></i>
          </span>
          </div>
          <p>
            Kings <span className="blue">|</span> 0
          </p>
          </a>
          </div>
        </SwiperSlide>
        <SwiperSlide className="below-swiper-slide">
        <div className="baller-wrapper">
        <a target="_blank" href="https://nbatopshot.com/user/@easymoneysniper_7">
          <img src={profile3} alt="" />
          <h5>Kevin Durant</h5>
          <div className="tickWrapper">
          <div className="topshot-name">easymoneysni...</div>
          <span>
            <i className="tickIcon fas fa-check"></i>
          </span>
          </div>
          <p>
            Kings <span>|</span> 7
          </p>
          </a>
          </div>
        </SwiperSlide>
        <SwiperSlide className="below-swiper-slide">
        <div className="baller-wrapper">
        <a target="_blank" href="https://nbatopshot.com/user/@ivicazubac40">
          <img src={profile4} alt="" />
          <h5>Ivica Zubac</h5>
          <div className="tickWrapper">
          <div className="topshot-name">ivicazubac40</div>
          <span>
            <i className="tickIcon fas fa-check"></i>
          </span>
          </div>
          <p>
            Kings <span>|</span> 40
          </p>
          </a>
          </div>
        </SwiperSlide>
        <SwiperSlide className="below-swiper-slide">
        <div className="baller-wrapper next">
          <img src={profile5} alt="" />
          <h5>Next to Join</h5>
          <div className="tickWrapper">
          <div className="topshot-name">the</div>
          </div>
          <p>
            Nine Lives Lounge
          </p>
          </div>
        </SwiperSlide>
      </Swiper>
      </div>

      {/* mobile view */}
      <div className="mobile-viewSlider">
      <Swiper
        spaceBetween={30}
        slidesPerView={2}
        grabCursor={true}
        navigation={false}
        modules={[Navigation]}
        className="belowSwiper"
      >
        <SwiperSlide className="below-swiper-slide">
          <img src={profile1} alt="" />
          <h5>Harrison Bar...</h5>
          <div className="tickWrapper">
          <div className="topshot-name">Hbarnes</div>
          <span>
            <i className="tickIcon fas fa-check"></i>
          </span>
          </div>
          <p>
            Kings <span>|</span> 40
          </p>
        </SwiperSlide>
        <SwiperSlide className="below-swiper-slide">
          <img src={profile2} alt="" />
          <h5>Tyrese Halib...</h5>
          <div className="tickWrapper">
          <div className="topshot-name">THO</div>
          <span>
            <i className="tickIcon fas fa-check"></i>
          </span>
          </div>
          <p>
            Kings <span>|</span> 0
          </p>
        </SwiperSlide>
        <SwiperSlide className="below-swiper-slide">
          <img src={profile3} alt="" />
          <h5>Kevin Durant</h5>
          <div className="tickWrapper">
          <div className="topshot-name">easymoneysni...</div>
          <span>
            <i className="tickIcon fas fa-check"></i>
          </span>
          </div>
          <p>
            Kings <span>|</span> 7
          </p>
        </SwiperSlide>
        <SwiperSlide className="below-swiper-slide">
          <img src={profile4} alt="" />
          <h5>Ivica Zubac</h5>
          <div className="tickWrapper">
          <div className="topshot-name">ivicazubac40</div>
          <span>
            <i className="tickIcon fas fa-check"></i>
          </span>
          </div>
          <p>
            Kings <span>|</span> 40
          </p>
        </SwiperSlide>
        <SwiperSlide className="below-swiper-slide">
          <img src={profile5} alt="" />
          <h5>Next to Join</h5>
          <div className="tickWrapper">
          <div className="topshot-name">the</div>
          </div>
          <p>
            Nine Lives Lounge
          </p>
        </SwiperSlide>
      </Swiper>
        </div>
    </>
  );
}
