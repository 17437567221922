import React from 'react'
import './news.scss';
import twitter1 from '../../../../../../assets/images/lounge/twitter1.png'
import twitter2 from '../../../../../../assets/images/lounge/twitter2.png'

const News = () => {

  const newsItems = [
    {title: "News Coming Soon", description: "", url: ""},
    {title: "News Coming Soon", description: "", url: ""},
    {title: "News Coming Soon", description: "", url: ""},
    // {title: "Upcoming NLL Events", description: "Upcoming NLL Events. Upcoming NLL Events", url: ""},
    // {title: "Council Newsletter / Blog", description: "Council Newsletters / Blog. Council Newsletter", url: ""},
    // {title: "NLL Member Spotlight", description: "", url: ""},
    // {title: "Top Shot Updates & Blog Headlines", description: "", url: ""},
    // {title: "Latest Proposal from the Council", description: "", url: ""},
    // {title: "Latest news", description: "Latest news. Latest news. Latest news", url: ""},
    // {title: "Latest news", description: "Latest news.", url: ""},
    // {title: "Latest news", description: "Latest news. Latest news. Latest news", url: ""},
    // {title: "Latest news", description: "Latest news. Latest news.", url: ""},
  ]

  return (
    <div className='news-main-wrapper'>
      <div className="lounge-subtitle akira">
        LATEST NEWS
      </div>
        <div className="news-inner-wrapper">
            <div className="news-items">
              {newsItems.map((newsItem, index) => {
                let {title, description, url} = newsItem;
                const processText = (text) => {
                  text = text.trim();
                  if(text.length > 0 && !text.endsWith(".")) {
                    text += ".";
                  }
                  return text;
                }
                const titleClass = index === 0 ? "title primary" : "title";
                return (
                  <a className="item-outer" key={`mediumnews-${index}`} href={url} target="_blank"><div className="item-inner"><span className={titleClass}>{processText(title)}</span> {processText(description)}</div></a>
                )
              })}
            </div>
        </div>
    </div>
  )
}

export default News;