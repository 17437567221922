import React from "react";
import "./community.scss";
import btnDiscord from "../../../../assets/images/metaverse/btnDiscord.png";
import btnTwitter from "../../../../assets/images/metaverse/btnTwitter.png";
import btnMedium from "../../../../assets/images/metaverse/btnMedium.png";

const Community = () => {
  return (
    <div className="community-main-wrapper">
      <div className="community-inner-wrapper">
        <h1>COMMUNITY</h1>
        <p>
          The IX. Labs community is a diverse community of basketball fanatics,
          NFT degenerates, and creatives of all kinds, Together, we 
          have taken on the challenge of building high-level web3 utility in the
          form of digital art, fashion, and fandom, to push NFT 
          standards to new heights. 
          
          Want to explore the metaverse with some of coolest cats around, and
          help establish an iconic brand around the NLL? Come 
          and join us on discord and learn what it means to{" "}
          <span>create your own utility</span>!
        </p>
        <div className="community-icons-wrapper">
          <div className="pink-line"></div>
          <div className="text">
            JOIN THE COMMUNITY
          </div>
          <div className="community-icons">
            <a target="_blank" href="https://discord.com/invite/TVRvVTwKZd"><img src={btnDiscord} alt="" /></a>
            <a target="_blank" href="https://twitter.com/IXLabs_"><img src={btnTwitter} alt="" /></a>
            <a target="_blank" href="https://medium.com/@NineLivesLounge"><img src={btnMedium
            } alt="" /></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Community;
