import {useEffect} from 'react'
import './lounge.scss'
import Header from "../../components/header";
import Footer from "../../components/footer";
import TopSlider from './components/topSlider';
import TheLounge from './components/theLounge';
import OriginStory from './components/originStory';

import catBgImg from "../../assets/images/backgrounds/cat-bg.png"


const Lounge = () => {
  useEffect(() => {
    document.body.classList.add('overflow-x-hidden');

    return () => {
        document.body.classList.remove('overflow-x-hidden');
    };
  });

  return (
    <div>
      <Header />
        <img
            src={catBgImg}
            className="cat-bg-img upper"
            alt="cat-outline"
          />
        <TopSlider />
        <div className="container">
          <TheLounge />
          <img
              src={catBgImg}
              className="cat-bg-img middle"
              alt="cat-outline"
            />
          <div className='overflow-hidden'>
            <img
                src={catBgImg}
                className="cat-bg-img lower"
                alt="cat-outline"
              />
          </div>
          <OriginStory />
        </div>
      <Footer />
    </div>
  )
}

export default Lounge