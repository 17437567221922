import React from "react";
import CurrentCouncilBodyCard from "./components";
import "./currentCouncilBody.scss";
import IMG1 from "../../../../assets/images/council/IMG1.jpeg";
import IMG2 from "../../../../assets/images/council/IMG2.jpg";
import IMG3 from "../../../../assets/images/council/IMG3.jpg";
import IMG4 from "../../../../assets/images/council/IMG4.jpg";
import IMG5 from "../../../../assets/images/council/IMG5.jpg";
import IMG6 from "../../../../assets/images/council/IMG6.jpeg";

const CurrentCouncilBody = () => {
  return (
    <div className="CurrentCouncilBody-main-wrapper">
      <div className="CurrentCouncilBody-inner-wrapper">
        <div className="CurrentCouncilBody-heading">Current Council Body</div>
        <div className="CurrentCouncilBody-text">Season o | The Draft</div>
        <div className="cardRow1">
          <CurrentCouncilBodyCard
            image={IMG1}
            heading="Rbspence"
            text="Council Chair"
          />
          <CurrentCouncilBodyCard
            image={IMG2}
            heading="Eddiedunks"
            text="Commissioner"
          />
          <CurrentCouncilBodyCard
            image={IMG3}
            heading="DK1"
            text="Head of Welcoming"
          />
        </div>
        <div className="cardRow2">
          <CurrentCouncilBodyCard
            image={IMG4}
            heading="Nbatopshotblazer"
            text="Events Coordinator"
          />
          <CurrentCouncilBodyCard
            image={IMG5}
            heading="Utkjmitch"
            text="Charity Coordinator"
          />
          <CurrentCouncilBodyCard
            image={IMG6}
            heading="Pshkins"
            text="Head of Innovation"
          />
        </div>
      </div>
    </div>
  );
};

export default CurrentCouncilBody;
