import React from "react";
import "./currentCouncilBodyCard.scss";
import twitterIcon from "../../../../../assets/images/council/twitterIcon.png";

const CurrentCouncilBodyCard = ({ image, heading, text }) => {
  return (
    <div className="CurrentCouncilBodyCard-main-wrapper">
      <div className="CurrentCouncilBodyCard-inner-wrapper">
        <img src={image} alt="" className="card-Img" />
        <div className="Card-Heading">{heading}</div>
        <div className="Card-Text">
          {text}
          <span>
            <img src={twitterIcon} alt="" className="Twitter-Icon" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default CurrentCouncilBodyCard;
