import React from "react";
import CurrentProjectsCard from "./components/currentProjectsCard";
import thirtyImg from "../../../../assets/images/metaverse/thirtyImg.png";
import masterCats from "../../../../assets/images/metaverse/masterCats.png";
import catPerch from "../../../../assets/images/metaverse/catPerch.png";
import "./currentProjects.scss";

const CurrentProjects = () => {
  return (
    <div className="currentProjects-main-wrapper">
      <div className="current-project-heading">
        <h1>CURRENT PROJECTS</h1>
      </div>

      <div className="currentProjects-cards-wrapper">
        <CurrentProjectsCard image={thirtyImg} url="/claim" title="THE 30/30 BADGE">
          <p>
            Celebrate the completion of your Cool
             Cats Moment™ set claim this exclusive NFT free for all current and
             future NLL members. 
          </p>
          <p>
          The 30/30 Badge grants the holder
             early access to future mints, exclusive
             discounts, private NFT alpha, and
             more. Become a member of the
             Lounge, claim your pass, and join us
             on our journey into the metaverse.
          </p>
        </CurrentProjectsCard>
        <CurrentProjectsCard image={masterCats} url="https://mastercatsnft.io" title="Master Cats NFT">
          <p>
          The Master Cats are the very first trait-based NFTs to make their way out of the NLL community. Inspired by the Top Shot Master Challenge, these diamond-handed cats are ready to venture out and conquer once more. Equipped with 99 unique traits and a gang of 9,000, the cats have set their sights on the mother of all mainnets as their first stop… Etherland!
          </p>
        </CurrentProjectsCard>
        <CurrentProjectsCard image={catPerch} comingSoon={true} title="The Cat Perch" mb>
          <p>
            The officIal{" "}
            <b>
              <i>
                Nine Lives VR Lounge & Court!
              </i>
            </b>
             {' '}A metaverse headquarters of
             the NLL - this membter's lounge is  filled with
            interactive games, giveaways, NFTs showrooms and more!</p>
            <p>
             Explore all nine levels and search for
             the hidden jersey on each floor. Be the
             first Cat to collect all 9 to receive an
             exclusive 1/1 NFT gift basket.
          </p>
          <div className="coming-soon-text">Coming Soon</div>
        </CurrentProjectsCard>
      </div>
    </div>
  );
};

export default CurrentProjects;
