
import React from 'react';
import EthereumSession from '../../../../lib/eth-session';
import CONFIG from '../../../../lib/config';
import './BeforeClaimSection.scss';

const BeforeClaimSection = props => {
    const { handleConnect, handleDisconnect, isConnected, session, setIsRewardClaimed } = props;


    const connectBtnClicked = () => {
        if( !isConnected )
            handleConnect( true );
    }

    const disconnectBtnClicked = () => {
        if( isConnected )
            handleDisconnect();
    }

    const claimNftBtnClicked = async () => {
        try{
            const account = session.wallet.accounts[0];
            const contractConfig = CONFIG.contracts.badge
            const contract = new session.web3client.eth.Contract(contractConfig.contractABI, contractConfig.contractAddress)
            contract.setProvider(session.provider)

            const claimed = parseInt(await contract.methods.claimed( account, 0 ).call());
            if( claimed > 0 ){
                setIsRewardClaimed( true );
                return;
            }


            const response = await fetch( `${contractConfig.signatureEndpoint}?account=${account}&contract=${contractConfig.contractAddress}&quantity=1` );
            if( response.ok ){
                const data = await response.json();

                try{
                    await contract.methods.mint( 0, 1, data.signature ).estimateGas({ from: account, value: 0 });
                    await contract.methods.mint( 0, 1, data.signature ).send({ from: account, value: 0 });
                }
                catch( err ){
                    if( err.code && err.code === -32602 ){
                        await contract.methods.mint( 0, 1, data.signature ).estimateGas({ from: account, value: 0, type: '0x1' });
                        await contract.methods.mint( 0, 1, data.signature ).send({ from: account, value: 0, type: '0x1' });
                    }
                    else{
                        throw err
                    }
                }

                setIsRewardClaimed( true );
            }
            else{
                alert( `Account ${account} is not verified` );
                return;
            }
        }
        catch( err ){
            const ethError = EthereumSession.getError( err );
			if( ethError.code === 4001 ){
				alert( "Please try again and confirm the transaction" );
			}
			else if( ethError.message ){
				alert( ethError.message );
			}
			else{
				alert( String( ethError ) );
			}
        }
    }


    const verifySetBtnClicked = (evt) => {
        window.open("https://dyno.gg/form/5672ddb7", '_blank', 'noopener,noreferrer');
    }


    return (
        <>
            <h4 className='fw-normal my-3'>Just completed the Cool Cats set?</h4>
            <h2 className='akira mt-0 mb-30 mb-2 claim-header'>Claim your <span className='tx-primary-3'>free</span> 30/30 badge now</h2>
            <div className='d-inline-block tx-end'>
                <h5 className='fw-normal mt-0 mb-30 mt-10'>For a one-time claim of the 30/30 Badge NFT, you must first verify your Cool Cats set completion on NBA Top Shot.<br/>Follow the steps below to claim your free mint:</h5>
                <ol className='m-0 pe-2 d-grid gap-1'>
                    <li>Click the <b>Verify Set</b> button below to enter your Ethereum Address in the approved <b>Claim List</b> Form</li>
                    <li>Login to your Discord account to verify your <b>NLL</b> role from the IX. Labs discord to access the Form</li>
                    <li>Enter your Ethereum Wallet Address in the <b>Claim List</b> form and hit <b>Submit</b></li>
                    <li>Allow time for your ETH Address to populate the approve list (this can take up to 24 hours)</li>
                    <li>Return to this claim portal and connect the Ethereum Wallet you provided</li>
                    <li>Mint your 30/30 Badge NFT and enjoy your travels through the metaverse!</li>
                </ol>
                <div className='note'>
                    Note: If you have not yet joined the IX. Labs discord and received the 'NLL' server role...follow the prompted link to join the server, and the subsequent instructions in the <b>#how-to-verify</b> channel to show proof of set ownership. Then return here and follow steps 1-6 to claim your 30/30 Badge NFT.
                </div>
                <div className='d-flex flex-column flex-md-row mt-30 gap-md-4 gap-3 gap-lg-5 pt-3 jc-center'>
                    <button onClick={verifySetBtnClicked} className='btn-primary h5 bg-dark bg-gradiant-secondary'>Verify Set</button>
                    {isConnected ? <button onClick={claimNftBtnClicked} className='btn-primary h5'>Claim NFT</button>
                        : <button className='btn-primary h5 bg-gradiant-secondary' onClick={connectBtnClicked}>Connect Wallet</button>}
                </div>
                <div className='d-flex flex-column flex-md-row mt-10 pt-1 jc-center'>
                    {isConnected && <div onClick={disconnectBtnClicked} className='disconnect-btn'>Disconnect</div>}
                </div>
            </div>
        </>
    )
}

export default BeforeClaimSection;
