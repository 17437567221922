import { useState, useEffect } from 'react';
import Header from "../../components/header";
import Badge from "./components/Badge";
import BeforeClaimSection from "./components/BeforeClaimSection";
import AfterClaimSection from "./components/AfterClaimSection";
import Footer from "../../components/footer";
import "./claim.scss";

import heroImg from '../../assets/images/claim/hero-img.png';

const Claim = props => {
  const { handleConnect, handleDisconnect, isConnected, session } = props;
  const [ isRewardClaimed, setIsRewardClaimed ] = useState(false);

  useEffect(() => {
    document.body.classList.add('bg-multi-cat');

    return () => {
      document.body.classList.remove('bg-multi-cat');
    };
  });

  return (
    <div className="claim-main-wrapper">
      <Header />
        <div className="container my-5">
          <img src={heroImg} alt="" className='w-100' />
          <div className='tx-center pt-2'>
              <Badge className='my-md-5 my-4' title='30/30 BADGE' />
              {isRewardClaimed ?
                <AfterClaimSection /> :
                <BeforeClaimSection isConnected={isConnected} handleConnect={handleConnect} handleDisconnect={handleDisconnect} session={session} setIsRewardClaimed={setIsRewardClaimed} />}
          </div>
          <div className='mt-5 pt-1 tx-center'>
            Verified Contract Address: <a href="https://etherscan.io/address/0x01aFE4Ed0CFee364307a67Ec4EE28ebe480833C3" target="_blank">0x01aFE4Ed0CFee364307a67Ec4EE28ebe480833C3</a>
          </div>
        </div>
        <Footer />
    </div>
  )
};

export default Claim;
