// Deps.
import BadgeABI from './ABIs/Badge'
import AlphaABI from './ABIs/Alpha'
import BetaABI from './ABIs/Beta'
import EthereumSession from './eth-session'

// Helper.
const connectionConfig = (opts = {}) => {
  return {
    ...opts,
    networkUrl: `https://${opts.network}.infura.io/v3/${opts.infuraId}`
  }
}

// Export.
export default {
    connection: connectionConfig({
        chain: EthereumSession.COMMON_CHAINS[1],
        network: 'mainnet',
        infuraId: '32642b7f94ed47a28b64add564c63c1b',
    }),
    contracts: {
        badge: {
          contractAddress: '0x01aFE4Ed0CFee364307a67Ec4EE28ebe480833C3',
          contractABI: BadgeABI,
          signatureEndpoint: 'https://apis.herodevelopment.com/remoteSig/',
        },
        alpha: {
          contractAddress: '0xf8e17742e6d1f95c688f2c1d6ac10316275f4478',
          contractABI: AlphaABI,
          signatureEndpoint: 'https://52kv1xw2o5.execute-api.us-east-1.amazonaws.com/prod/presale-signature',
        },
        beta: {
          contractAddress: '0x44804C7C8134fA54aA410a0678c3953390DcCa2f',
          contractABI: BetaABI,
          signatureEndpoint: 'https://52kv1xw2o5.execute-api.us-east-1.amazonaws.com/prod/presale-signature',
        },
    },
}
