import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./metaverse.scss";
import IntoTheMetaverse from "./components/intoTheMetaverse";
import CurrentProjects from "./components/currentProjects";
import Roadmap from "./components/roadmap";
import Community from "./components/community";
import IxLabs from "./components/ixLabs";
import MetaverseHQ from "./components/metaverseHQ";
import Faqs from "../../components/faqs";

const Metaverse = () => {
  return (
    <div className="metaverse-main-wrapper">
      <Header />
      <MetaverseHQ />
      <IntoTheMetaverse />
      <CurrentProjects />
      <Roadmap />
      <Community />
      <IxLabs />
      <Faqs />
      <Footer />
    </div>
  );
};

export default Metaverse;
