import React from "react";
import "./agendaBudget.scss";
import walletPlaceholder from "../../../../assets/images/council/walletPlaceholder.png";

const AgendaBudget = () => {
  return (
    <div className="AgendaBudget-main-wrapper">
      <div className="AgendaBudget-inner-wrapper">
        <div className="AgendaBudget-leftSide">
          <div className="page-heading left AgendaBudget-Heading1">
            <div className="title">CURRENT AGENDA</div>
          </div>
          <p className="AgendaBudget-text1 top">
            <b>-{' '}</b> Current council agenda. current council agenda
          </p>
          <p className="AgendaBudget-text1">
            <b>-{' '}</b> council agenda. current council agenda
          </p>
          <p className="AgendaBudget-text1">
            <b>-{' '}</b> Agenda. current council agenda
          </p>
          <div className="page-heading left AgendaBudget-Heading2">
            <div className="title">REMAINING BUDGET</div>
          </div>
          <p className="AgendaBudget-text2">
            {/* <b>-{' '}</b> */}
            <span className="AgendaBudget-Value">$15,0779.29</span> for the
            month of May
          </p>
        </div>
        <div className="AgendaBudget-rightSide">
          <h3 className="AgendaBudget-NLL-Wallet"> NLL Community Wallet</h3>
          <div className="walletPlaceholder-img">
            <img className="img-fluid" src={walletPlaceholder} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgendaBudget;
