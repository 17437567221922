import './AfterClaimSection.scss';
import discord from '../../../../assets/images/icons/discord.png';

const AfterClaimSection = ({ onClaimReward }) => {

    const nllOnTsBtnClicked = (evt) => {
        window.open("https://discord.gg/nbatopshot");
    }

    const ogNllBtnClicked = (evt) => {
        window.open("https://discord.com/invite/rGbupYStW4");
    }

    const ixLabsBtnClicked = (evt) => {
        window.open("https://discord.gg/TVRvVTwKZd");
    }

    return (
        <>
            <h5 className='fst-italic fw-normal my-3'>Congratulations on completing your Cool Cats set! <br className='d-md-block d-none' /> On behalf of the community...</h5>
            <h1 className={`akira bg-gradiant-primary mb-5 pb-md-5 tx-gradiant section-2-root`}>Welcome to the <br className='d-md-block d-none' /> Nine Lives Lounge !</h1>
            <div className='join-discord-text'>Join us on Discord <img className="section-2-discord" src={discord} alt="" /></div>
            <div className='gap-lg-4 gap-3 d-lg-flex d-grid jc-center mt-20 mb-30'>
                <button onClick={nllOnTsBtnClicked} className='btn-border radius-8 btn-primary bg-dark bg-gradiant-primary'>NLL ON TOP SHOT</button>
                <button onClick={ogNllBtnClicked} className='btn-border radius-8 btn-primary bg-dark bg-gradiant-primary'>OG NLL</button>
                <button onClick={ixLabsBtnClicked} className='btn-border radius-8 btn-primary bg-dark bg-gradiant-primary'>IX. LABS</button>
            </div>
        </>
    )
}

export default AfterClaimSection;