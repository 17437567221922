import React, { Children } from "react";
import "./currentProjectsCard.scss";

const CurrentProjectsCard = ({ image, url, comingSoon, children, title, mb }) => {
  const comingSoonClass = comingSoon ? " coming-soon" : "";

  const Content = () => {
    return (
      <div
        className={`currentProjectsCard-main-wrapper ${comingSoonClass}`}
        style={mb ? { marginBottom: 0 } : null}
      >
        <img src={image} alt="cardimage" />
        <div className="title">{title}</div>
        {children}
      </div>
    );
  }

  // internal page
  if(url && url.startsWith("/")) {
    return (
      <a className="basic" href={url}>
        <Content />
      </a>
    )
  }
  else if(url) {
    return (
      <a className="basic" href={url} target="_blank">
        <Content />
      </a>
    )
  }
  else {
    return (<Content />);
  }
};

export default CurrentProjectsCard;
