import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import headerLogo from "../../assets/images/nll/nine-lives-lounge.png";
import headerMiddleCat from "../../assets/images/nll/cat-colored.png";
import topshot from "../../assets/images/icons/topshot.png";
import twitter from "../../assets/images/icons/twitter.png";
import discord from "../../assets/images/icons/discord.png";
import "./header.scss";
import SideNav from "./components/sideNav";

const usePathname = () => {
  const location = useLocation();
  return location.pathname;
}

const Header = () => {
  const [open, setOpen] = useState(false);

  const pathname = usePathname();

  return (
    <>
      <div className="header-main-wrapper">
        <div className="inner-wrapper">
          <div className="logo-wrapper">
            {/* <i className="fal fa-bars"></i> */}
            <button
              className={open ? "menu opened" : "menu"}
              onClick={() => setOpen((prev) => !prev)}
              aria-label="Main Menu"
            >
              <svg width="40" height="40" viewBox="0 0 100 100">
                <path
                  className="line line1"
                  d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                />
                <path className="line line2" d="M 20,50 H 80" />
                <path
                  className="line line3"
                  d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                />
              </svg>
            </button>
            <a href="/portal"><img src={headerLogo} alt="headerLogo" /></a>
          </div>
          <div className="middle-icon-wrapper">
          <a href="/portal"><img src={headerMiddleCat} alt="headerMiddleCat" /></a>
          </div>
          <div className="right-links-wrapper">
            <div className="links-w">
              <a href="http://hoo.ps/cool-cats-moments-kd" target="_blank">
                <div className="soc-btn topshot"></div>
              </a>
              <a href="https://twitter.com/NineLivesLounge" target="_blank">
                <div className="soc-btn twitter"></div>
              </a>
              <a href="https://discord.gg/nbatopshot" target="_blank">
                <div className="soc-btn discord"></div>
              </a>
            </div>
            {/* <div className="connect-wallet-btn">
              <span>Connect Wallet</span>
            </div> */}
          </div>
        </div>
      </div>
      <SideNav open={open} pathname={pathname} />
    </>
  );
};

export default Header;
