import React from 'react'
import './mediumNews.scss';
import twitter1 from '../../../../../../assets/images/lounge/twitter1.png'
import twitter2 from '../../../../../../assets/images/lounge/twitter2.png'

const MediumNews = () => {

  const newsItems = [
    {title: "Want a ticket to the Nine Lives Lounge? Here’s your chance", description: "", url: "https://nineliveslounge.medium.com/want-a-ticket-to-the-nine-lives-lounge-heres-your-chance-to-score-a-complete-cool-cats-set-22f5bc7e56ac"},
    {title: "Steady Breaks and Nine Lives Lounge Team up for Exclusive NLL-Members-Only Break Nights", description: "", url: "https://nineliveslounge.medium.com/steady-breaks-and-nine-lives-lounge-team-up-for-exclusive-nll-members-only-break-nights-fc7ae520919a"},
    {title: "5/9/22 NLL Community Update", description: "", url: "https://nineliveslounge.medium.com/5-9-22-nll-community-update-6c97c2609bbe"},
  ]

  return (
    <div className='news-main-wrapper mt-50'>
      <div className="lounge-subtitle akira">
        LATEST ARTICLES
      </div>
        <div className="news-inner-wrapper">
            <div className="news-items">
              {newsItems.map((newsItem, index) => {
                let {title, description, url} = newsItem;
                const processText = (text) => {
                  text = text.trim();
                  if(text.length > 0 && !text.endsWith(".")) {
                    text += ".";
                  }
                  return text;
                }
                const titleClass = index === 0 ? "title primary" : "title";
                return (
                  <a className="item-outer" key={`news-${index}`} href={url} target="_blank"><div className="item-inner"><span className={titleClass}>{processText(title)}</span> {processText(description)}</div></a>
                )
              })}
            </div>
        </div>
    </div>
  )
}

export default MediumNews;