import React from "react";
import "./roadmap.scss";

const Roadmap = () => {
  return <div className="roadmap-main-wrapper">
    <div className="roadmap-inner-wrapper">
      <h1>ROADMAP</h1>
      <p> <i> To be Announced Soon! </i></p>
    </div>
  </div>;
};

export default Roadmap;
