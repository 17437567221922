import React from "react";
import "./salesCard.scss";
import lastSale from "../../../../../../assets/images/lounge/lastSale.jpg";
const SalesCard = () => {
  return (
    <div className="salesCard-main-wrapper">
      <div className="salesCard-inner-wrapper">
        <div className="ImgWrapper">
          <img src={lastSale} alt="" />
        </div>
        <div className="salesCard-content">
          <div className="text">
          <div>
            <div className="sale-text">
              NBA TOP SHOT<p>LeMelo Ball Dunk</p>
            </div>
          </div>
          <div>
            <div className="sale-text price">
              PRICE<p>$2,995</p>
            </div>
          </div>
          </div>
        <div className="cardText sale-text">
          January 30 2021 <br />
          Cool Cats (Series 2) <br />
        </div>
        </div>
      </div>
    </div>
  );
};

export default SalesCard;
