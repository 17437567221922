import axios from "axios";
import React, { Component } from "react";

import "./approved.scss";
import headerImg from "../../../assets/images/portal/portal-header.png";

class AdminApproved extends Component {
  state = {
    // Initially, no file is selected
    file: null,
    error: "",
    success: "",
    allApproved: [],
    password: "",
  };

  // On file select (from the pop up)
  onFileChange = (event) => {
    // Update the state
    this.setState({ file: event.target.files[0] });
  };

  onPasswordChange = (event) => {
    // Update the state
    this.setState({});
  };

  // On file upload (click the upload button)
  onFileUpload = async () => {
    // Create an object of formData
    // formData.append(
    //   this.state.title,
    //   this.state.file.name,
    //   this.state.password,
    // );

    const form = new FormData();
    form.append("password", this.state.password);
    form.append("file", this.state.file);

    // Details of the uploaded file
    console.log(this.state);

    // Request made to the backend api
    // Send formData object
    //const url = `${process.env.REACT_APP_API_URL}/approvedaddresses/setApprovedFromCsv`;
    const url = `https://mastercatsnft.io:7778/api/approvedaddresses/setApprovedFromCsv`;
    // console.log(url, process.env.API_URL)
    const response = await axios.post(url, form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const data = await response.data;
    if (data.error) {
      this.setState({ error: data.error, success: "" });
    } else if (data.success) {
      this.setState({
        error: "",
        success: data.success,
        allApproved: data.allApproved,
      });
    } else {
      this.setState({ error: "Unknown Error Occurred", success: "" });
    }
  };

  // File content to be displayed after
  // file upload is complete
  fileData = () => {
    if (this.state.file) {
      return (
        <div>
          <h2>File Details:</h2>
          <p>File Name: {this.state.file.name}</p>
          <p>File Type: {this.state.file.type}</p>
          <p>
            Last Modified: {this.state.file.lastModifiedDate.toDateString()}
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <h4>Please choose a file then press upload</h4>
        </div>
      );
    }
  };

  showResponseMessage = () => {
    return (
      <div>
        <div className="error-msg">{this.state.error}</div>
        <div className="success-msg">{this.state.success}</div>
        {this.state.allApproved.length > 0 && (
          <div className="approved-title">{`All approved addresses in DB (${this.state.allApproved.length})`}</div>
        )}
        {this.state.allApproved.map((approved, index) => {
          return (
            <div className="approved-item r-mono" key={`address_${index}`}>
              {approved.address}
            </div>
          );
        })}
      </div>
    );
  };

  updatePassword(evt) {
    const val = evt.target.value;
    console.log(val);
    this.setState({
      password: val.trim(),
    });
  }

  render() {
    return (
      <div className="m-auto text-center">
        <img src={headerImg} className="mt-100 mb-30" width="400" />
        <h2 className="mb-30 akira">Approved Addresses Dashboard</h2>
        <h5>Upload .CSV with latest approved addresses</h5>
        <div className="mb-30">
          <input type="file" onChange={this.onFileChange} />
          <button onClick={this.onFileUpload}>Upload</button>
        </div>
        <h5>Enter Password</h5>
        <input
          name="password"
          type="password"
          className="mb-50"
          placeholder="Password"
          value={this.state.password}
          onChange={(evt) => this.updatePassword(evt)}
        />
        {this.fileData()}
        {this.showResponseMessage()}
      </div>
    );
  }
}

export default AdminApproved;
