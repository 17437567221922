import React from "react";
import "./loungeNews.scss";
import SalesCard from "./components/salesCard";
import ProfileSlider from "./components/ProfileSlider";
import News from "./components/News";
import MediumNews from "./components/MediumNews";
import TwitterFeed from "./components/TwitterFeed";
import BlogPosts from "./components/BlogPosts";

const LoungeStack = () => {
  return (
    <div className="lounge-news">
      <div className="container">
      {/* <h1>CERTIFIED BALLERS</h1> */}
      {/* <div className="certifiedNews-inner-wrapper">
        <div className="certifiedNews-leftSide">
          <ProfileSlider/>
        </div>
        <div className="certifiedNews-rightSide">
          <p>Recent Sales</p>
          <SalesCard />
        </div>
      </div> */}
      <div className="row">
        <div className="col-12 col-sm-12 col-lg-9">
          <ProfileSlider/>
          <News />
          <MediumNews />
        {/* <BlogPosts /> */}
        </div>
        <div className="col-12 col-sm-12 col-lg-3">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-12">
              <div className="sales-container">
                <div className="side-heading">Recent Sales</div>
                <SalesCard />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-12">
              <div className="twitter-feed-container">
                <div className="side-heading mt-20 mb-0">Twitter Feed</div>
                  <TwitterFeed />
                </div>
              </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default LoungeStack;
