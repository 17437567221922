
import React, { useState, useRef, useEffect } from 'react';
import ReactCursorPosition from 'react-cursor-position';
import { Navigation, Autoplay, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/autoplay/autoplay.scss';
import 'swiper/modules/mousewheel/mousewheel.scss';

import './Portal.scss';
import nextBtnImg from '../../assets/images/buttons/next.svg';
import prevBtnImg from '../../assets/images/buttons/prev.svg';
import portalHeaderImg from '../../assets/images/portal/portal-header.png';
import portalBgShapeImg from '../../assets/images/portal/portal-bg-shape.png';
import portalFooterImg from '../../assets/images/portal/portal-footer-logo.png';
import portalSwitchAnimImg from '../../assets/images/portal/portal-switch-anim.gif';


const portals = [
  {
      url: `/lounge`,
      artClass: `art-lounge`,
      comingSoon: false,
      uiIndex: 2,
  },
  {
      url: `/council`,
      artClass: `art-council`,
      comingSoon: true,
      uiIndex: 3,
  },
  {
      url: `/store`,
      artClass: `art-lounge-store`,
      comingSoon: true,
      uiIndex: 1,
  },
  {
      url: `/scratching-post`,
      artClass: `art-scratching-post`,
      comingSoon: true,
      uiIndex: 4,
  },
  {
      url: `/cat-cave`,
      artClass: `art-cat-cave`,
      comingSoon: true,
      uiIndex: 5,
  },
  {
      url: `/metaverse`,
      artClass: `art-metaverse`,
      comingSoon: false,
      uiIndex: 1,
  },
];

// class PortalSwitchAnim extends React.Component {
//   constructor(props){
//     super(props)
//     this.state = {
//       gif: 'https://i.imgur.com/zrDbg5j.gif',
//       loaded: 'https://i.imgur.com/zrDbg5j.gif',
//     }
//   }
  
//   reloadGif = () => {
//     this.setState({loaded: ''})
//     setTimeout(() => {
//       this.setState({loaded: `${this.state.gif}?${this.props.randomNumber}`})
//     }, 0)
//   }
  
//   render(){
//     return (
//       <div id="portalLoop" className="fs-img anim" style={{ backgroundImage: `url(${this.state.loaded})`}}></div>
//     )
//   }
// }

const PortalSwitchAnim = ({randomNumber, visible}) => {
  const animClass = `fs-img anim ${visible ? 'visible' : 'hidden'}`
  return (
    <div id="portalLoop" className={animClass} style={{ backgroundImage: `url(${portalSwitchAnimImg}?${randomNumber})`}}></div>
  )
}

const PortalRingLight = ({uiIndex, visible}) => {
  const lightClass = `fs-img light light-${uiIndex} ${visible ? 'fadeIn' : 'fadeOut'}`;
  return (
    <div className={lightClass}></div>
  )
}

const PortalRingActive = ({uiIndex, visible}) => {
  const activeWrapperClass = `fs-img ${visible ? 'active-wrapper' : ''}`;
  const activeClass = `fs-img active active-${uiIndex} ${visible ? 'fadeIn' : 'fadeOut'}`;
  return (
    <div className={activeWrapperClass} id="portalActiveWrapper">
      <div className={activeClass} id="portalActive"></div>
    </div>
  )
}

const PortalArt = ({artClass, visible}) => {
  artClass = `fs-img art ${artClass} ${visible ? 'fadeIn' : 'fadeOut'}`;
  return (
    <div className={artClass} id="portalArt"></div>
  )
}

const PortalBtn = ({comingSoon, visible, url}) => {
  const comingSoonClass = comingSoon ? 'coming-soon' : '';
  const btnText = comingSoon ? 'Coming Soon' : 'GO';
  const visibleClass = visible ? 'visible' : '';

  // const Button = () => {
  //   if(!comingSoon && url) {
  //     return (
  //       <div className={`portal-btn ${comingSoonClass} ${visibleClass}`}><a href={url} className='basic'>{btnText}</a></div>
  //     )
  //   }
  //   return (
  //     <div className={`portal-btn ${comingSoonClass} ${visibleClass}`}>{btnText}</div>
  //   )
  // }

  if(!comingSoon && url) {
    return (<a href={url} className='basic'><div className={`portal-btn ${comingSoonClass} ${visibleClass}`}>{btnText}</div></a>)
  }

  return (
    <div className={`portal-btn ${comingSoonClass} ${visibleClass}`}>{btnText}</div>
  )
}

const PortalOverlay = ({visible}) => {
  const artClass = `fs-img overlay ${visible ? 'fadeIn' : 'fadeOut'}`;
  return (
    <div className={artClass}></div>
  )
};


export default function Portal() {
  const [portalIndex, setPortalIndex] = useState(0);
  const [portalLightVisible, setPortalLightVisible] = useState(false);
  const [portalActiveVisible, setPortalActiveVisible] = useState(false);
  const [portalArtVisible, setPortalArtVisible] = useState(false);
  const [portalSwitchAnimVisible, setPortalSwitchAnimVisible] = useState(true);
  const [portalBtnVisible, setPortalBtnVisible] = useState(false);
  const [portalBtnComingSoon, setPortalBtnComingSoon] = useState(false);
  const [portalBtnUrl, setPortalBtnUrl] = useState("/");
  //const [portal, setPortalSwitchAnimVisible] = useState(true);
  const [portalSwitchAnimBgRandom, setPortalSwitchAnimBgRandom] = useState(Math.random());
  const [portalArtClass, setPortalArtClass] = useState('art-lounge');

  useEffect(() => {
    document.body.classList.add('bg-multi-cat');

    return () => {
        document.body.classList.remove('bg-multi-cat');
    };
  });

  //setPortal(portalIndex);
  console.log("portalIndex (portal base)", portalIndex);
  const {url, artClass, comingSoon, uiIndex} = portals[portalIndex];
  
  
  //$(`.portal-selector`).fadeIn(850)
  
  function setPortal(portalIndex) {
    console.log('setPortal', portalIndex);
    const {url, artClass, comingSoon} = portals[portalIndex];
    console.log("artClass (portal base)", artClass);

    // flash the selected portal light overlay
    setPortalLightVisible(true);
    setPortalBtnVisible(false);
    setTimeout(() => {
      setPortalLightVisible(false);
    }, 250)

    setTimeout(() => {
      // fade out the artwork
      setPortalArtVisible(false);
      setTimeout(() => {
        // set new artwork and fade in
        setPortalArtClass(artClass);
        setPortalArtVisible(true);
      }, 350);
      
      // fade out the active glowing portal overlay
      setPortalActiveVisible(false);
      setTimeout(() => {
        // set new active segment and fade in
        setPortalActiveVisible(true);
        setPortalBtnVisible(true);
        setPortalBtnComingSoon(comingSoon);
        setPortalBtnUrl(url);
      }, 500);

      // fade out the glowing portal overlay
    //     $(`#portalActive`).fadeOut(500).promise().done(() => {
    //         $('#portalActive').removeClass("active-1 active-2 active-3 active-4 active-5");
    //         // swap to the selected portal's glow overlay
    //         $('#portalActive').addClass(`active-${uiIndex}`);
    //         resetCSSAnimation('portalActiveWrapper'); // reset css anim so glow comes in at the brightest level
    //         $(`#portalActive`).fadeIn(300);
    //     });
      setPortalSwitchAnimVisible(false);
      setPortalSwitchAnimBgRandom(Math.random());
      setTimeout(() => {
        setTimeout(() => {
          setPortalSwitchAnimVisible(true);
        }, 300)
      }, 50)
    }, 250)
    
    // setTimeout(() => {
    //     // fade out the glowing portal overlay
    //     $(`#portalActive`).fadeOut(500).promise().done(() => {
    //         $('#portalActive').removeClass("active-1 active-2 active-3 active-4 active-5");
    //         // swap to the selected portal's glow overlay
    //         $('#portalActive').addClass(`active-${uiIndex}`);
    //         resetCSSAnimation('portalActiveWrapper'); // reset css anim so glow comes in at the brightest level
    //         $(`#portalActive`).fadeIn(300);
    //     });
    //     // restart animation gif
    //     $(`#portalLoop`).css('background-image', `url(/img/portal/portal-switch-anim.gif?${Math.random()})`)
    // }, 250);
  }

  return (
      <main className="portal">
        <div className="fs-img-base">
          <PortalSwitchAnim randomNumber={portalSwitchAnimBgRandom} visible={true} />
          {/* <PortalSwitchAnim randomNumber={portalSwitchAnimBgRandom} visible={portalSwitchAnimVisible} /> */}
          <PortalArt artClass={portalArtClass} visible={portalArtVisible} />
          <PortalOverlay visible={portalBtnComingSoon} />
          <div className="fs-img portal"></div>
          {/* <ReactCursorPosition>
            <PortalOverlay  visible={true} />
          </ReactCursorPosition> */}
          <PortalRingActive uiIndex={uiIndex} visible={portalActiveVisible} />
          <PortalRingLight uiIndex={uiIndex} visible={portalLightVisible} />
          <Swiper
            modules={[Navigation, Autoplay, Mousewheel]}
            navigation={{
              nextEl: ".swiper-next",
              prevEl: ".swiper-prev",
            }}
            className="portal-selector"
            autoplay={{delay: 12000, disableOnInteraction: true}}
            mousewheel={{releaseOnEdges: true}}
            threshold={5}
            simulateTouch={true}
            centeredSlides={true}
            speed={600}
            rewind={true}
            slidesPerView={1}
            onSwiper={() => {
              console.log("onSwiper")
              setPortal(0);
            }}
            onSlideChange={(swiper) => {
              setPortalIndex(swiper.realIndex);
              setPortal(swiper.realIndex);
              //const portalIndex = portalSwiper.realIndex;
              // temporarily hide swiper buttons
              //$('.swiper-next, .swiper-prev').css("opacity", 1).animate({opacity: 0}, 400).promise().done(() => {
                  //$('.swiper-next, .swiper-prev').css("opacity", 0).animate({opacity: 1}, 800);
              //});
              //setPortal(portalIndex);
            }}
          >
            <SwiperSlide>
              <div className="top row">
              <div className="col-2 left">
                <div className='portal-nav swiper-prev'>
                  <img src={prevBtnImg} />
                </div>
              </div>
                <div className="col-8 portal-title">
                  The Lounge
                </div>
              <div className="col-2 right">
                <div className='portal-nav swiper-next'>
                  <img src={nextBtnImg} />
                </div>
              </div>
              </div>
              <div className="bottom">
                The unofficial headquarters for all things NLL. This Web3 portal is the Lounge's own virtual community hub. 
                Here you can learn all about the history of the Nives Lives Lounge, claim free
                congratulatory NFTs, discover which members are Certified Ballers,
                and check out the latest news &amp; updates from the community.
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="top row">
              <div className="col-2 left">
                <div className='portal-nav swiper-prev'>
                  <img src={prevBtnImg} />
                </div>
              </div>
              <div className="col-8 portal-title">
                NLL Council
              </div>
              <div className="col-2 right">
                <div className='portal-nav swiper-next'>
                  <img src={nextBtnImg} />
                </div>
              </div>
              </div>
              <div className="bottom">
                The office of the chief stewards of the NLL.<br />
                Venture through this portal to hear about the council's current
                agenda, read up on our community's by-laws, and find out which NLL
                members serve on the council and in what capacity. This portal hub
                is also home to our classic NLL poker nights, as well as our NLL
                Events &amp; Charities bulletins.
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="top row">
            <div className="col-2 left">
                <div className='portal-nav swiper-prev'>
                  <img src={prevBtnImg} />
                </div>
              </div>
                  <div className="col-8 portal-title">
                    Lounge Store
                  </div>
                  <div className="col-2 right">
                <div className='portal-nav swiper-next'>
                  <img src={nextBtnImg} />
                </div>
              </div>
                </div>
                <div className="bottom">
                  A NLL Web3 storefront that will feature professional clothing and
                  merchandise designed in partnered with some of the biggest Sports
                  brands, and sold in the form of unlockable digital wearables &amp;
                  collectibles. Get ready to rep your Fandom in both the phyiscal and
                  digital worlds!
                </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="top row">
            <div className="col-2 left">
                <div className='portal-nav swiper-prev'>
                  <img src={prevBtnImg} />
                </div>
              </div>
                  <div className="col-8 portal-title">
                    Scratching Post
                  </div>
                  <div className="col-2 right">
                <div className='portal-nav swiper-next'>
                  <img src={nextBtnImg} />
                </div>
              </div>
                </div>
                <div className="bottom">
                  Welcome to <b>The Scratching Post</b> — a members-only feedback, forum, and polling system. 
                  Open to all NLL members, this portal is our innovation hub where community
                  members can share ideas and upvote proposals directly to the NLL
                  Council, and/or IX. Labs Team. If you have a concept on how to
                  increase utility in the Lounge and want help implementing? Come by
                  to the Scratching Post and let your voice be heard.
                </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="top row">
            <div className="col-2 left">
                <div className='portal-nav swiper-prev'>
                  <img src={prevBtnImg} />
                </div>
              </div>
                  <div className="col-8 portal-title">
                    The Cat Cave
                  </div>
                  <div className="col-2 right">
                <div className='portal-nav swiper-next'>
                  <img src={nextBtnImg} />
                </div>
              </div>
                </div>
                <div className="bottom">
                  A den full of degenerates, <b>The Cat Cave</b> is a hub of NFT marketplace 
                  tools and analytics, both crafted and
                  curated with NLL members in mind. Here is where you find all the
                  latest resources for next-level NBA Top Shot collecting, juicy NFT
                  alpha, and more. If you're ready to track challenges and anticipate
                  market trends like the best of them, swing by the lair and get ready
                  to learn.
                </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="top row">
            <div className="col-2 left">
                <div className='portal-nav swiper-prev'>
                  <img src={prevBtnImg} />
                </div>
              </div>
                  <div className="col-8 portal-title">
                    Metaverse
                  </div>
                  <div className="col-2 right">
                <div className='portal-nav swiper-next'>
                  <img src={nextBtnImg} />
                </div>
              </div>
                </div>
                <div className="bottom">
                  Join the NLL Cool Cats as they begin their journey into the
                  metaverse! This portal is your gateway to learn all about the
                  first-ever <b>Nine Lives VR Lounge &amp; Court</b>, as well as our
                  game-changing NLL Avatars that will allow you to rep your fandom
                  everywhere you go! Unearth all that and more in this metaverse
                  headquarters built and powered by IX. Labs.
                </div>
            </SwiperSlide>
            <PortalBtn comingSoon={portalBtnComingSoon} visible={portalBtnVisible} url={portalBtnUrl} />
          </Swiper>
          {/* <div className="btn-container">
          </div> */}
          {/* <div className="swiper portal-selector" style={{display: "none"}}>

          </div> */}
        </div>
        <div className="header-img wow fadeInDown" data-wow-delay=".2s">
          <img src={portalHeaderImg} className="img-fluid" />
        </div>
        <div className="header-icons">
          <div className="icon">
            <a href="http://hoo.ps/cool-cats-moments-kd" target="_blank">
              <div className="soc-btn topshot"></div>
            </a>
          </div>
          <div className="icon middle">
            <a href="https://twitter.com/NineLivesLounge" target="_blank">
              <div className="soc-btn twitter"></div>
            </a>
          </div>
          <div className="icon bottom">
            <a href="https://discord.gg/nbatopshot" target="_blank">
              <div className="soc-btn discord"></div>
            </a>
          </div>
        </div>
        {/* <img
          src={portalBgShapeImg}
          className="shape-portal"
          alt="shape"
        /> */}
        <footer className="portal-footer">
          <div className="footer-bottom">
            <div className="flex-row">
              <div className="footer-rights dummy">
              </div>
              {/* /.footer-rights */}
              <div className="footer-logo">
                <img src={portalFooterImg} alt="logo-footer" />
                {/* /.colap */}
              </div>
              <div className="footer-rights">
                <p>
                  <a className="footer-link" href="#!">PRIVACY POLICY</a>
                  <br />
                  © 2022 NINE LIVES LAB LLC
                </p>
              </div>
            </div>
          </div>
          {/* /.footer-bottom */}
        </footer>
      </main>
  );
}
