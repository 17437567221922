
import React from 'react';
import { Routes, Route } from "react-router-dom";
import Landing from "./pages/landing";
import Council from "./pages/council";
import Claim from "./pages/claim";
import Metaverse from "./pages/metaverse";
import Portal from "./pages/portal";
import Lounge from "./pages/lounge";
import LoungeOrientation from "./pages/loungeOrientation";
import Approved from "./pages/admin/approved";

import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink";
import Web3Modal from "web3modal";
import CONFIG from './lib/config';
import EthereumSession from './lib/eth-session';

export default function App(){
  const [ isConnected, setConnected ] = React.useState( false );
  const { connection } = CONFIG

  const session = React.useMemo(() => {
    return new EthereumSession(connection);
  }, []);

  const web3modal = React.useMemo(() => {
    const modal = new Web3Modal({
      network: connection.network,
      cacheProvider: false,
      disableInjectedProvider: false,
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: connection.infuraId,
          },
        },
        "custom-walletlink": {
          package: WalletLink,
          display: {
            name: "Coinbase",
            description: "Connect to Coinbase Wallet",
            logo: "/images/coinbase.jpg",
          },
          options: {
            appName:    "Coinbase",
            chainId:    connection.chain.decimal,
            networkUrl: connection.networkUrl
          },
          connector: async () => {
            const walletLink = new WalletLink({ appName: 'Coinbase' });
            const provider = walletLink.makeWeb3Provider(connection.networkUrl, connection.chain.decimal);
            await provider.enable();
            return provider;
          }
        }
      }
    });
    return modal;
  }, []);

  const handleDisconnect = async () => {
    if(!isConnected) {
      return;
    }
    if(window.ethereum.close) {
      try {
        await window.ethereum.close();
      }
      catch (err) {
        
      }
    }
    await web3modal.clearCachedProvider();
    setConnected( false );
    // allow user to alter accounts
    await session.requestPermissions();
    return true;
  }

  React.useEffect(() => {
    handleDisconnect();
  }, []);

  const handleConnect = async ( deep ) => {
    let provider = window.ethereum;

    if( deep ){
      try{
        provider = await web3modal.connect();
      }
      catch( err ){
        if( !(err === 'Modal closed by user' && err.code === 4001) ){
          console.warn({ err });
          alert('Connection failed, please try again');
        }

        return false;
      }
    }

    if( await session.connectWeb3( deep, provider ) )
      setConnected( true );

    return false;
  }

  React.useEffect(() => {
    handleConnect();
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/metaverse" element={<Metaverse />} />
        <Route path="/council" element={<Council />} />
        <Route path="/claim" element={<Claim handleConnect={handleConnect} handleDisconnect={handleDisconnect} isConnected={isConnected} session={session} />} />
        <Route path="/portal" element={<Portal />} />
        <Route path="/orientation" element={<LoungeOrientation />} />
        <Route path="/lounge" element={<Lounge />} />

        <Route path="/adminapproved" element={<Approved />} />
      </Routes>
    </>
  );
}
