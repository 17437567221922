import {useEffect} from 'react'
import './loungeOrientation.scss'
import Header from "../../components/header";
import Footer from "../../components/footer";


const LoungeOrientation = () => {
  useEffect(() => {
    document.body.classList.add('overflow-x-hidden');

    return () => {
        document.body.classList.remove('overflow-x-hidden');
    };
  });

  return (
    <div>
      <Header />
        <div className="container">
          <div className="lounge-orientation-header akira">
            Gitbook HERE
          </div>
        </div>
      <Footer />
    </div>
  )
}

export default LoungeOrientation