import React from "react";
import "./footer.scss";

import ixLabsLogo from "../../assets/images/footer/ix-labs-logo.png"

const Footer = () => {
  return (
    <footer className="nll-footer">
      <div className="footer-bottom">
        <div className="flex-row">
          <div className="footer-rights dummy">
          </div>
          {/* /.footer-rights */}
          <div className="footer-logo">
            <img src={ixLabsLogo} alt="logo-footer" />
            {/* /.colap */}
          </div>
          <div className="footer-rights">
            <p>
              <a className="footer-link" href="#!">PRIVACY POLICY</a>
              <br />
              © 2022 NINE LIVES LAB LLC
            </p>
          </div>
        </div>
      </div>
      {/* /.footer-bottom */}
    </footer>
  );
};

export default Footer;
