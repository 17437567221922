import React from "react";
import "./ixLabs.scss";
import teamJustmacn from "../../../../assets/images/metaverse/teamJustmacn.jpg";
import teamScotty from "../../../../assets/images/metaverse/teamScotty.jpg";
import teamCherry from "../../../../assets/images/metaverse/teamCherry.jpg";
import teamCoach from "../../../../assets/images/metaverse/teamCoach.jpg";

const IxLabs = () => {
  return (
    <div className="ixLabs-main-wrapper">
      <div className="ixLabs-inner-wrapper">
        <h1 className="ixLabs-heading">IX. LABS</h1>
        <p className="ixLabs-text">
          IX. Labs is a white-glove Web3 & NFT service provider, built and
          designed by founding members of the NLL. We are 
          a professional team of visual artists, project managers, financial
          executives, and software developers, championing 
          the single goal of seeing the Nine Lives Lounge become a global brand
          and the #1 digital community in the world.
        </p>
        <div className="ixLabs-image-container">
          <div className="ixLabs-leftSide">
            <div className="image-row1">
              <img src={teamJustmacn} alt="" />
              <img src={teamScotty} alt="" />
            </div>
            <div className="image-row2">
              <img src={teamCoach} alt="" />
              <img src={teamCherry} alt="" />
            </div>
          </div>
          <div className="ixLabs-rightSide"> 
            <h1>JustMac'n <span>- Creative Director</span></h1>
            <h1>Scottyj111 <span>- Chief of Operations</span></h1>
            <h1>CoachP1421 <span>- Community Manager</span></h1>
            <h1>Cherry <span>- Lead Engineer</span></h1>
          </div>
        </div>
        <p className="ixLabs-bottomText">IX. Labs is a third-party Web3 provider and is not affliated with Dapper Labs or NBA Top Shot.</p>
      </div>
    </div>
  );
};

export default IxLabs;
