import React from "react";
import "./intoTheMetaverse.scss";

const IntoTheMetaverse = () => {
  return (
    <div className="intoTheMetaverse-main-wrapper">
      <div className="intoTheMetaverse-inner-wrapper">
        <div className="page-heading left">
          <div className="title">INTO THE METAVERSE</div>
        </div>
        <p>
          Welcome to NLL headquarter of the ever-expanding Metaverse. Here
          lounge members can discover unique and innovative ways to <br />
          interact with one another virtually, as well as rep their fandom
          across the globe. Powered by IX. Labs, this portal hub is for everyone{" "}
          <br />
          who loves to <span className="create"><i>create</i></span> dope content, <span className="collect"><i>collect</i></span>{" "}
          their latest NFTs, and <span className="collab"><i>collab</i></span> with some of the freshest
          creatives in the space. <br /> <br />
          Whether you're new to NFTs or a veteran...grab a seat and strap in,
          because the NLL is beginning its next challenge of mastering the metaverse!<br />
        </p>
      </div>
    </div>
  );
};

export default IntoTheMetaverse;
