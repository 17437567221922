import React, { useState } from "react";
import topshot from "../../../../assets/images/icons/topshot.png";
import twitter from "../../../../assets/images/icons/twitter.png";
import discord from "../../../../assets/images/icons/discord.png";
import "./sideNav.scss";

const SideNav = ({ open, pathname }) => {
  const simplePathname = pathname.split("/")[1];
  const [listItems, setListItems] = useState([
    {
      name: "The Lounge",
      url: "lounge",
      comingSoon: false,
    },
    {
      name: "NLL Council",
      url: "council",
      comingSoon: true,
    },
    {
      name: "Lounge Store",
      url: "store",
      comingSoon: true,
    },
    {
      name: "The Scratching Post",
      url: "scratching-post",
      comingSoon: true,
    },
    {
      name: "The Cat Cave",
      url: "cat-cave",
      comingSoon: true,
    },
    {
      name: "Metaverse",
      url: "metaverse",
      comingSoon: false,
    },
  ]);

  const joinLoungeBtnClicked = (evt) => {
    window.open("https://nbatopshot.com/sets/ad8e85a4-2240-4604-95f6-be826966d988");
  }

  return (
    <div
      className="sideNav-main-wrapper"
      style={!open ? { width: "0px", height: "0px", padding: "0px" } : null}
    >
      <div className="links-w">
        <a href="#">
          <img src={topshot} alt="topshot" />
        </a>
        <a href="#">
          <img src={twitter} alt="twitter" />
        </a>
        <a href="#">
          <img src={discord} alt="discord" />
        </a>
      </div>
      <a href="/portal" className="no-underline"><div className="sideNav-heading akira">Portal HUB</div></a>
      <ul className="sideNav-list">
        {listItems.map((item, i) => (
          <li key={i}>
            {!item.comingSoon &&  <a href={`/${item.url}`} className={item.url === simplePathname ? 'active' : null}>
            {item.name}
            {item.comingSoon && (
              <span className="coming-soon">coming soon</span>
            )}
            </a>}
            {item.comingSoon && <a className={item.url === simplePathname ? 'active' : null}>
            {item.name}
            {item.comingSoon && (
              <span className="coming-soon">coming soon</span>
            )}
            </a>}
          </li>
        ))}
      </ul>
      <div className="join-lounge-btn-wrapper">
        <div onClick={joinLoungeBtnClicked} className="join-lounge-btn">
          <span>Join the Lounge</span>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
