import React from "react";
import heroBg from "../../../../assets/images/council/herobg.jpg";
import heroimg from "../../../../assets/images/council/heroimg.png";
import "./topSection.scss";

const TopSection = () => {
  return (
    <div className="topSection-main-wrapper">
      <div 
        className="home-fs-img"
        style={{
          backgroundImage: `url(${heroBg})`,
        }}
      >
        <div className="page-hero-heading mb-45">
          <div className="title pt-0">
            NLL COUNCIL
          </div>
          <div className="underline">
        </div>
      </div>
        <div className="home-text-img">
          <img className="img-fluid" src={heroimg} />
        </div>
      </div>
      {/* <div
        className="background-image"
        style={{
          backgroundImage: `url(${heroBg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="black-layer">
          <h1>
            <span>NLL COUNCIL</span>
          </h1>
          <div className="front-image">
            <img src={heroimg} alt="heroimg" />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default TopSection;
