import React from "react";
import Header from "../../components/header";
import TopSection from "./components/topSection";
import CouncilAtLarge from "./components/councilAtLarge";
import CurrentCouncilBody from "./components/currentCouncilBody";
import AgendaBudget from "./components/agendaBudget";
import Footer from "../../components/footer";
import "./council.scss";
import Faqs from "../../components/faqs";

const Council = () => {
  return (
    <div className="council-main-wrapper">
      <Header />
      <TopSection />
      <CouncilAtLarge />
      <CurrentCouncilBody />
      <AgendaBudget />
      <Faqs />
      <Footer />
    </div>
  );
};

export default Council;
