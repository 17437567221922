import React from "react";
import LoungeNews from "../loungeNews";
import "./theLounge.scss";

const TheLounge = () => {
  return (
    <div className="theLounge-main-wrapper">
      <div className="theLounge-inner-wrapper">
        <div className="page-hero-heading mb-15">
            <div className="title pt-0">
              THE LOUNGE
            </div>
            <div className="underline">
          </div>
        </div>
        <p>
            The Nine Lives Lounge is an exclusive community for collectors of
            all 30 Cool Cats Moment™ NFTs on NBA Top Shot. <br />
            Welcome to home of the coolest cats in all of crypto!
        </p>
      </div>
      <LoungeNews />
    </div>
  );
};

export default TheLounge;
